import {
    Grid,
    Typography,
  } from "@mui/material";

function ServiceButton ({ title, onClick, icon, iconHover, onClickIcon, onHoverColor, onClickColor, disabled }) {
    return(
        <Grid
            container
            spacing={2}
            sx={{ 
                display: "flex", 
                borderRadius: "16px",
                background: disabled ? "#C1C1D0" : "#FFF",
                boxShadow: "0px 4px 4px 0px rgba(128, 112, 252, 0.13)",
                paddingRight: "0px",
                justifyContent: "left",
                alignItems: "center",
                height: "64px",
                margin: { xs: " 0 0 17px 0", lg: "20px 0 0 0" },
                transition: "all ease-out 300ms",
                border: disabled ? "none" : "1px solid transparent",
                pointerEvents: disabled ? "none" : "initial",
                cursor: disabled ? "initial" : "pointer",
                "&: hover" : {
                    borderColor: "#5640FB",
                    "p:first-of-type": {
                        color: disabled ? "" : `${onHoverColor}`,
                    },
                    "img:first-of-type": {
                        content: disabled ? `url(${icon})` : `url(${iconHover})`
                    }
                },
                "&: active": {
                    background: disabled ? "" : `${onClickColor}`,
                    "img:first-of-type": {
                        content: disabled ? `url(${icon})` : `url(${onClickIcon})`
                    },
                    "p:first-of-type": {
                        color: disabled ? "" : "#FFF",
                    },
                }
            }}
            data-testid="service-button"
            onClick={onClick}
        >
            <Grid>
                <Typography variant="body2"
                    sx={{
                        textAlign: "left",
                        color: "#505050",
                        fontSize: "18px"
                    }}
                >
                    <img 
                        src={icon} 
                        width="32px"
                        style={{ verticalAlign: "middle", margin: "0 0 0 15px" }}
                        alt="Title"
                    /> { title }
                </Typography>
            </Grid>
        </Grid>
    );
}

export default ServiceButton;
