// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState } from "react";
import { getCurrentUser, signInWithRedirect } from "aws-amplify/auth";

import Header from "./components/Header.js";
import Dashboard from "./containers/Dashboard";
import config from "./config.js";

import "./App.css";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    validateCurrentSession();
  }, []);

  const validateCurrentSession = async () => {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (error) {
      signInWithRedirect({
        provider: {
          custom: config.cognito.SAML_IDP_NAME
        }
      });
      console.log(error);
    }
  };

  return (
    <div className="App">
      {user && (
        <>
          <Header />
          <Dashboard />
        </>
      )}
    </div>
  );
}

export default App;
