import { Grid, Typography, Box } from "@mui/material";
import { Loader } from "./Loader/Loader";

function DashboardButton({
  bgColor,
  title,
  onClick,
  icon,
  disabled,
  onHoverColor,
  iconHover,
  hoverIconContainerColor = "",
  hoverBorderColor = "",
  loading = false,
  splitTitle = false,
}) {
  const splitTitleArray = splitTitle ? title.split(" ") : [title];

  return (
    <Grid item onClick={disabled ? ()=>{} :onClick} xs={12} sm={6} lg={4} data-testid="dashboard-button">
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "16px",
          background: disabled ? "#C1C1D0" : bgColor,
          boxShadow: "0px 4px 4px 0px rgba(128, 112, 252, 0.13)",
          height: { sm: "144px", lg: "181px" },
          pointerEvents: disabled ? "none" : "initial",
          userSelect: "none",
          cursor: disabled ? "initial" : "pointer",
          border: loading ? "none" : "2px solid transparent",
          transition: "all ease-out 300ms",
          position: "relative",
          "&: hover": {
            background: disabled ? "" : `${onHoverColor}`,
            borderColor: hoverBorderColor,
            boxShadow: "0px 4px 4px 0px rgba(86, 64, 251, 0.36)",
            "h1:first-of-type": {
              color: disabled ? "" : `${bgColor}`,
            },
            "img:first-of-type": {
              content: disabled ? `url(${icon})` : `url(${iconHover})`,
            },
            ".icon-container": {
              background: hoverIconContainerColor,
            },
          },
          "&: active": {
            transition: "all ease-in 300ms",
            background: "#FFF",
            ".icon-container": {
              background: "transparent",
            },
          },
        }}
      >
        <Loader isLoading={loading} primary={false} />
        <Grid container>
          <Grid
            item
            sx={{
              margin: {xs: "24px 32px 0", lg: "24px 48px 0"},
              display: "flex",
              padding: "8px !important",
              alignItems: "center",
              gap: "10px",
              borderRadius: "8px",
              background: "rgba(255, 255, 255, 0.20)",
            }}
            className="icon-container"
          >
            <Box
              sx={{
                width: { xs: "32px", lg: "56px" },
                height: { xs: "32px", lg: "56px" },
              }}
            >
              <img
                src={icon}
                alt="nbcu_ticket_icon"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sx={{ paddingTop: "0px !important", marginTop: {xs: "8px", lg: "16px"}, marginRight: 0, marginBottom: splitTitle ? {xs: "24px", lg: "21px"} : "44px", marginLeft: {xs: "32px", lg: "48px"} }}>
            <Typography
              variant="h1"
              component="h1"
              fontSize={20}
              fontWeight={700}
              textAlign="center"
              color="#FFF"
            >
              {splitTitleArray[0]}
            </Typography>
            {splitTitle && splitTitleArray[1] && (
              <Typography
                variant="h1"
                component="h1"
                fontSize={20}
                fontWeight={700}
                textAlign="center"
                color="#FFF"
              >
                {splitTitleArray[1]}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashboardButton;
