import {
    Grid,
    Typography,
  } from "@mui/material";
import checkIcon from "../assets/img/check.svg";

function BrandsContainer ({ img, title }) {
    return(
        <Grid
            container
            spacing={2}
            sx={{ 
                display: "flex", 
                borderRadius: "4px",
                border: "1px solid #5640FB",
                background: "#5640FB",
                paddingRight: "0px",
                justifyContent: "left",
                alignItems: "center",
                alignSelf: "stretch",
                height: "64px",
                marginTop: "-4px",
            }}
        >
            <Grid 
                item 
                sx={{ 
                    display: "flex",
                    paddingLeft: "0 !important",
                    paddingTop: "0 !important"
                }}
            >
                <img 
                    src={img} 
                    width="48px"
                    alt="Brand logo"
                    style={{ verticalAlign: "middle", marginLeft: "12px", marginRight: "16px" }}
                />
            </Grid>
            <Grid 
                item 
                xs
                sx={{ 
                    display: "flex",
                    paddingLeft: { sm: "0 !important" }, 
                    paddingTop: "0 !important"
                }}
            >
                <Typography variant="body1" fontSize="14px" color="#FFF" sx={{ textAlign: "left", fontWeight: "500" }}>
                    { title }
                </Typography>
            </Grid>
            <Grid 
                item 
                sx={{ 
                    display: "flex",
                    paddingLeft: { lg: "0 !important" },
                    paddingTop: {xs: "0 !important"},
                    justifyContent: "flex-end",
                    paddingRight: "13px !important"
                }}
            >
                <img 
                    src={checkIcon} 
                    width="24px"
                    style={{ 
                        verticalAlign: "middle", 
                        marginRight: "15px"
                    }}
                    alt="Check Icon"
                />
            </Grid>
        </Grid>
    );
}

export default BrandsContainer;
