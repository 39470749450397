import {
    Snackbar,
    Alert
} from "@mui/material";

function AlertMessage({ flag, message, closeAction, severity, bgColor, border, bxShadow, textColor }){
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={flag}
            autoHideDuration={4000}
            onClose={closeAction}            
        >
            <Alert 
                severity={severity} 
                sx={{ 
                    width: '100%',
                    backgroundColor: bgColor,
                    border: border,
                    boxShadow: bxShadow,
                    color: textColor }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default AlertMessage;