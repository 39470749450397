import React, { useEffect, useCallback, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    List,
    ListItem
  } from "@mui/material";
import DashboardButton from "../components/DashboardButton";
import ServiceButton from "../components/ServiceButton";
import BrandsContainer from "../components/BrandsContainer";
import { Loader } from "../components/Loader/Loader";
import AlertMessage from "../components/Alert";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import focus from "../assets/img/focus.png";
import uaa from "../assets/img/uaa.png";
import questionIcon from "../assets/img/shield-question.svg";
import hover_questionIcon from "../assets/img/hover_shield-question.svg";
import click_questionIcon from "../assets/img/click_shield-question.svg";
import bookIcon from "../assets/img/book.svg";
import hover_bookIcon from "../assets/img/hover_book.svg";
import click_bookIcon from "../assets/img/click_book.svg";
import userIcon from "../assets/img/icon-user.svg";
import hover_userIcon from "../assets/img/hover_user.svg";
import click_userIcon from "../assets/img/click_user.svg";
import content from "../assets/img/content.svg";
import cart from "../assets/img/cart.svg";
import rewards from "../assets/img/rewards.svg";
import analytics from "../assets/img/analytics.svg";
import people from "../assets/img/people.svg";
import badge from "../assets/img/badge.svg";
import hover_content from "../assets/img/hover_content.svg";
import hover_cart from "../assets/img/hover_cart.svg";
import hover_rewards from "../assets/img/hover_rewards.svg";
import hover_analytics from "../assets/img/hover_analytics.svg";
import hover_people from "../assets/img/hover_people.svg";
import hover_badge from "../assets/img/hover_badge.svg";
import config from '../config';

function Dashboard() {

    const mailto = config.email?.MAIL_TO || "";
    const [isLoading, setLoading] = useState(true);    
    const [isContentButtonLoading, setContentButtonLoading] = useState(false);
    const [name, setName] = useState("Jane");
    const [ globalTitle , setGlobalTitle] = useState("Focus Features");
    const [ globalImg , setGlobalImg] = useState(focus);
    const [ globalRole , setGlobalRole] = useState("");
    const [ idTkn, setIdTkn] = useState("");
    const [tools, setTools] = useState({
        Content: false,
        Commerce: false,
        Rewards: false,
        Analytics: false,
        Customer_Account: false,
        Internal_Users: false
    });
    const [rightButtons, setRightButtons] = useState({
        User_Guide: false,
        Support: false,
        Service_Portal: false
    });

    const [showBrandLogo, setShowBrandLogo] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertState, setAlertState] = useState(true);

    const [customTenant, setCustomTenant] = useState("Tenant");

    const isAuthenticatedUser = useCallback(async () => {
        const user = await getCurrentUser();
        if(user) {
            const currentS = await fetchAuthSession();
            if(currentS) {
                try {
                    const { tokens: { idToken } } = currentS || {};
                    setIdTkn(idToken.toString());

                    const {payload : { given_name = "" } } = idToken || {};
                    setName(given_name);
                    
                    const permissionsURL =`${config.nbcuapis.FRONTEND_API_BASE_URL}`;
                    const response = await fetch(permissionsURL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken.toString()}`,
                        },
                        mode: "cors",
                        referrerPolicy: "strict-origin-when-cross-origin"
                    });
                    const resp = await response.json();
                    if(resp) {
                        const { message } = resp || {};
                        const { tenant = "", brand_role = "", permissions = {} } = message || {};
                        
                        if(Object.keys(permissions).length > 0) {
                            setCustomTenant(tenant);
                            if(tenant === "uaa") {
                                setGlobalTitle("Universal All-Access");
                                setGlobalImg(uaa);
                                setShowBrandLogo(true);
                            } else if(tenant === "no_brand"){
                                console.log("noooo brand")
                                setShowBrandLogo(false);
                            }
                            
                            if(brand_role) {
                                setGlobalRole(brand_role);
                                if(tenant !== "no_brand")
                                    setShowBrandLogo(true);
                            }

                            if(permissions) {
                                setTools(permissions.management_tools);
                                setRightButtons(permissions.buttons);
                            }
                        }else {
                            setShowBrandLogo(false);
                            setShowAlert(true);
                        }
                    }
                } catch (error) {
                    console.log("error")
                    console.log(error);
                }
            }
        }
        setLoading(false);
    }, [])

    useEffect(() => {
        isAuthenticatedUser();
      }, [isAuthenticatedUser]);

    const callMultipass = async () => {
        setContentButtonLoading(true);
        try {
            const params = {
                tenant_id: customTenant
            };
            var multipassEndpoint =`${config.nbcuapis.REACT_APP_CREATE_MULTIPASS}`;
            const response = await fetch(multipassEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idTkn}`,
                },
                body: JSON.stringify(params),
                mode: "cors",
                referrerPolicy: "strict-origin-when-cross-origin"
            });
            const resp = await response.json();
            const { message = "" } = resp || {};
            if(message) {
                const {redirectionUrl,encryptedToken} = message;                
                let cmsStrapiEndpoint = `${redirectionUrl}/admin?token=${encryptedToken}`;
                window.open(cmsStrapiEndpoint); 
            } else {
                console.log("Error, couldn't create token");
            }
        }catch(err) {
            console.log("error");
            console.log(err);
        }
        setContentButtonLoading(false);
    };

    const openCommerceTools = async () => {
        setContentButtonLoading(true);
        try {
            console.log("Opening CommerceTools");
            const commerceToolsRedirection =`${config.commerceTools.COMMERCE_TOOLS_URL}`;
            window.open(commerceToolsRedirection); 
        }catch(err) {
            console.log("error");
            console.log(err);
        }
        setContentButtonLoading(false);
    };
  return (
    <React.Fragment>
        <Loader isLoading={isLoading} />
        <Box sx={{ flexFlow: 1, padding: "10px 48px", display: "flex", maxWidth: "1440px", margin: "0 auto" }}>
            <Grid
                container
                spacing={2}
                sx={{
                width: "100%",
            }}
            >
                {/* Title */}
                <Grid item xs={12} mb={2}>
                    {showAlert &&
                        <AlertMessage 
                            flag={alertState}
                            message="An error occurred. Please contact the administrator"
                            closeAction={()=>{
                                setAlertState(false);
                                // window.location.href = "https://login.stg.inbcu.com/login/logoff.jsp";
                            }}
                            severity="error"
                            bgColor="#FFE9ED"
                            border="1px solid #DA1E28"
                            bxShadow="0px 2px 2px 0px rgba(0, 0, 0, 0.07)"
                            textColor="#27251F"
                        />
                    }
                    <Grid container spacing={2} marginLeft={0}>
                        <Grid item sx={{width: {sm: "100%", md: "405px", lg: "324px"}}}>
                            <Grid 
                                container 
                                spacing={2}
                                sx={{ 
                                    height: "64px",
                                    background: "#FFF", 
                                    display: "flex", 
                                    justifyContent: "center", 
                                    borderRadius: "4px",
                                }}
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body1"
                                        fontSize={18}
                                        fontWeight={400}
                                        textAlign="left"
                                    >
                                        Hello
                                        <span style={{ fontWeight: "700"}}> {name}! </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ 
                                    paddingRight: "16px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    height: "40px",
                                    alignItems: "center"
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={12}
                                        fontWeight={400}
                                        textAlign="center"
                                        color="#505050"
                                        sx={{
                                            padding: "4px 8px",
                                            borderRadius: "16px",
                                            background: "rgba(128, 112, 252, 0.13)"
                                        }}
                                    >
                                        { globalRole }
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                            {showBrandLogo &&
                                <BrandsContainer 
                                    img={globalImg}
                                    title={globalTitle}
                                />
                            }

                            <Grid
                                container
                                spacing={2}
                            >

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <Grid container spacing={2}
                                            sx={{ 
                                                display: "flex", 
                                                borderRadius: "4px",
                                                background: "#FFF",
                                                paddingRight: "0px",
                                                paddingTop: {xs: "16px", md: "initial"},
                                                justifyContent: "left",
                                                alignItems: "center",
                                                minWidth: {xs: "100%", md: "324px"},
                                                maxWidth: {xs: "100%", md: "405px", lg: "324px"},
                                                margin: { xs: "50px 0 0 0", lg: "50px 0 0 0" }
                                            }}
                                        > 
                                            <Grid item xs={12}>
                                                <Typography 
                                                    variant="body1"
                                                    sx={{
                                                        textAlign: "left",
                                                        color: "#311DC8",
                                                        fontSize: "18px"
                                                    }}
                                                >
                                                    Welcome to <span style={{ fontWeight: "700" }}>Raptor!</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{paddingTop: "0 !important"}}>
                                            <   Typography 
                                                    variant="body1"
                                                    sx={{
                                                        textAlign: "left",
                                                        color: "#697077",
                                                        fontSize: "16px"
                                                    }}
                                                >
                                                    Some things you can do here include:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <List sx={{ listStyleType: 'disc', paddingLeft: "16px", paddingBottom: {xs: "32px", md: "16px"} }}>
                                                    <ListItem sx={{ display: 'list-item', color: "#697077", paddingLeft: "0", paddingBottom: "0", paddingTop: "0", lineHeight: "1.1" }}>Manage Content</ListItem>
                                                    <ListItem sx={{ display: 'list-item', color: "#697077", paddingLeft: "0", paddingBottom: "0", paddingTop: "0", lineHeight: "1.1" }}>Manage Commerce offers</ListItem>
                                                    <ListItem sx={{ display: 'list-item', color: "#697077", paddingLeft: "0", paddingBottom: "0", paddingTop: "0", lineHeight: "1.1" }}>Access Analytics</ListItem>
                                                    <ListItem sx={{ display: 'list-item', color: "#697077", paddingLeft: "0", paddingBottom: "0", paddingTop: "0", lineHeight: "1.1" }}>Manage Raptor internal users</ListItem>
                                                    <ListItem sx={{ display: 'list-item', color: "#697077", paddingLeft: "0", paddingBottom: "0", paddingTop: "0", lineHeight: "1.1" }}>Manage Customer user accounts</ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <Grid container sx={{ marginTop: { xs: "16px", sm: "50px", md: "18px", lg: "0" } }}>
                                            <ServiceButton 
                                                title="Download User Guide"
                                                icon={bookIcon} 
                                                iconHover={hover_bookIcon}
                                                onClickIcon={click_bookIcon}
                                                onHoverColor="#5640FB"
                                                onClickColor="#5640FB"
                                                disabled={!rightButtons.User_Guide}
                                            />
                                            <ServiceButton 
                                                title="Request Raptor Support"
                                                icon={questionIcon} 
                                                iconHover={hover_questionIcon}
                                                onClickIcon={click_questionIcon}
                                                onHoverColor="#5640FB"
                                                onClickColor="#5640FB"
                                                disabled={!rightButtons.Support}
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    window.open(`mailto:${mailto}`, '_blank');
                                                }}
                                            />
                                            <ServiceButton 
                                                title="Customer Service Portal"
                                                icon={userIcon} 
                                                iconHover={hover_userIcon}
                                                onClickIcon={click_userIcon}
                                                onHoverColor="#5640FB"
                                                onClickColor="#5640FB"
                                                disabled={!rightButtons.Service_Portal}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>                                
                            </Grid>
                        </Grid>

                        {/* Second Grid */}
                        <Grid item sm md lg sx={{paddingLeft: "0 !important", paddingTop: {sm: "8px !important", md: "0 !important" }}}>
                            <Grid sx={{paddingLeft: {md: "16px !important"}, paddingTop: "0 !important" }}>
                            <Typography
                                variant="h1"
                                component="h1"
                                fontSize={32}
                                fontWeight={500}
                                color={"#21272A"}
                                textAlign="left"
                            >
                                Management tools
                            </Typography>
                            <Grid container spacing={2} marginTop={1}>
                                <DashboardButton 
                                    loading={isContentButtonLoading}
                                    bgColor="#5640FB"
                                    title= "Content"
                                    onClick={callMultipass} 
                                    icon={content}
                                    disabled={!tools.Content}
                                    onHoverColor="#E5E1FF"
                                    iconHover={hover_content}
                                    hoverIconContainerColor="rgba(128, 112, 252, 0.13)"
                                    hoverBorderColor="#5640FB"
                                />
                                <DashboardButton 
                                    bgColor="#DF146F"
                                    title= "Commerce"
                                    onClick={openCommerceTools} 
                                    icon={cart}
                                    disabled={!tools.Commerce}
                                    onHoverColor="#FFEFF6"
                                    iconHover={hover_cart}
                                    hoverIconContainerColor="rgba(223, 20, 111, 0.20)"
                                    hoverBorderColor="#DF146F"
                                />
                                <DashboardButton 
                                    bgColor="#FA6930"
                                    title= "Rewards"
                                    onClick={()=>{}} 
                                    icon={rewards}
                                    disabled={!tools.Rewards}
                                    onHoverColor="#FFEFE9"
                                    iconHover={hover_rewards}
                                    hoverIconContainerColor="rgba(250, 105, 48, 0.20)"
                                    hoverBorderColor="#FA6930"
                                />
                                <DashboardButton 
                                    bgColor="#21B9FC"
                                    title= "Analytics"
                                    onClick={()=>{}} 
                                    icon={analytics}
                                    disabled={!tools.Analytics}
                                    onHoverColor="#DBF4FF"
                                    iconHover={hover_analytics}
                                    hoverIconContainerColor="rgba(33, 185, 252, 0.20)"
                                    hoverBorderColor="#21B9FC"
                                />
                                <DashboardButton 
                                    bgColor="#34B53A"
                                    title= "Customer Accounts"
                                    onClick={()=>{}} 
                                    icon={people}
                                    disabled={!tools.Customer_Account}
                                    onHoverColor="#CDFFCF"
                                    iconHover={hover_people}
                                    hoverIconContainerColor="rgba(52, 181, 58, 0.20)"
                                    hoverBorderColor="#34B53A"
                                    splitTitle
                                />
                                <DashboardButton 
                                    bgColor="#FFB200"
                                    title= "Internal Users"
                                    onClick={()=>{}} 
                                    icon={badge}
                                    disabled={!tools.Internal_Users}
                                    onHoverColor="#FFF2D3"
                                    iconHover={hover_badge}
                                    hoverIconContainerColor="rgba(255, 178, 0, 0.20)"
                                    hoverBorderColor="#FFB200"
                                />
                            </Grid>
                            </Grid>
                        </Grid>
                        {/* Second Grid */}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </React.Fragment>
  );
}

export default Dashboard;
