// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from "react";
import { getCurrentUser, fetchAuthSession, signOut } from "aws-amplify/auth";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import log_out_icon from "../assets/img/log-out.svg";
import config from '../config';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingTop: 24, // keep right padding when drawer closed
        paddingBottom: 32,
        paddingLeft: "0 !important",
        paddingRight:"0 !important",
        '& > *': {
            marginLeft: '25px',
            marginRight: '25px',
        },
        "& h4": {
            paddingLeft: "0"
        },
        marginTop: "30px"
    },
    navLinksContainers: {
        flexGrow: 1,
        marginLeft: "2em",
        marginRight: 0,
        textAlign: "right",
        "& > *": {
            marginLeft: "1em",
            fontSize: "1em",
            color: "#21272A",
            textDecoration: "none",
        },
        "& img": {
          verticalAlign: "middle",
          margin: "0 0 0 15px"
        }
    },
}));

function Header(props) {
    const classes = useStyles(props);
    // const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [ setUserGroups ] = React.useState([]);

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                // bypassCache: Optional, By default is false. 
                // If set to true, this call will send a request to Cognito to get the latest user data. Without this you may experience old user data
                // const user =  await Auth.currentAuthenticatedUser({bypassCache: true});
                const user =  await getCurrentUser({bypassCache: true});
                if (user) {
                    const currentS = await fetchAuthSession();
                    const { tokens: { idToken } } = currentS || {};
                    const {payload : { "cognito:groups": cognitoGroups = [] } } = idToken || {};
                    console.log(cognitoGroups);
                    setUserGroups(cognitoGroups);
                }
            } catch (e) {
            if (e !== 'The user is not authenticated') {
                //console.warn(e);
            }
            }
        }
        getUserInfo()
    }, [setUserGroups]);

    const links = () => {
        return (
            <div className={classes.navLinksContainers}>
                <NavLink to="" onClick={handleSignOut}>
                    Log Out
                <img 
                  src={log_out_icon} 
                  width="24px"
                  alt="Log out Icon" />
                </NavLink>
            </div>
        )
    }

    const handleSignOut = async() => {
        
            console.log("About to signout");
            await signOut({global: true})
            .then(() =>{
                console.log("Signout complete");
                console.log("Redirecting: ",config.cognito.LOG_OFF);
                window.location.href = config.cognito.LOG_OFF;
                
            })
            .catch(err => { 
                console.log("Error signing out",err);
                console.log("Redirecting: ",config.cognito.LOG_OFF);
                window.location.href = config.cognito.LOG_OFF;
        });

    }

    return (
        <div data-testid="header">
            <AppBar position="static" style={{ background: '#DDDAE8', boxShadow: "none", padding: "0 48px", maxWidth: "1440px", margin: "0 auto" }}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h4" textAlign="left" color="#21272A" paddingLeft={4} fontWeight="700" fontSize="42px"> Raptor Platform </Typography>
                    { authStatus === 'authenticated' ? links() : null }
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;
