const configuration =  {
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_9MGtxmkiF',
    APP_CLIENT_ID: '4o8f5v8n1go7962q0qpkvq9g07',
    IDENTITY_POOL_ID: 'us-west-2:143b7af7-4ca8-4647-b127-01451a83ea81',
    SUPERADMIN_GROUP_NAME: 'Film',
    COGNITO_IP: 'cognito-idp.us-west-2.amazonaws.com/us-west-2_9MGtxmkiF',
    DOMAIN_URL: 'stage-raptor-admin-idp.auth.us-west-2.amazoncognito.com',
    SAML_IDP_NAME: 'PingFederate',
    SSO_REDIRECT_SIGN_IN_URL: 'https://stage.raptor.nbcuniversal.com',
    SUPERADMIN_GROUP_KEY: 'custom:groups',
    COGNITO_MAPPED_NBCU_EMAIL: 'custom:email_address',
    IDENTITY_PROVIDER_SAML_NBCU_EMAIL: 'Email',
    COGNITO_MAPPED_SSO_USER_ID: 'custom:uid',
    IDENTITY_PROVIDER_SAML_SSO_USER_ID: 'UID',
    COGNITO_MAPPED_NBCU_BUSINESS_UNIT: 'custom:business_unit',
    IDENTITY_PROVIDER_SAML_NBCU_BUSINESS_UNIT: 'BusinessUnit',
    COGNITO_MAPPED_NBCU_USER_TYPE: 'custom:user_type',
    IDENTITY_PROVIDER_SAML_NBCU_USER_TYPE: 'UserType',
    LOG_OFF: 'https://stage-raptor-admin-idp.auth.us-west-2.amazoncognito.com/logout?client_id=4o8f5v8n1go7962q0qpkvq9g07&logout_uri=https://login.stg.inbcu.com/login/logoff.jsp'
  },
  nbcuapis:{
    REACT_APP_CREATE_MULTIPASS: 'https://26d7cammwk.execute-api.us-west-2.amazonaws.com/stage/multipass-token',
    FRONTEND_API_BASE_URL: 'https://zsifrr60pi.execute-api.us-west-2.amazonaws.com/stage/frontend-policies'
  },
  strapi:{
    REACT_APP_STRAPI: 'https://staging.cms.raptor.nbcuniversal.com/admin?token='
  },
  commerceTools:{
    COMMERCE_TOOLS_URL: 'https://mc.us-central1.gcp.commercetools.com/login/sso/NBCU'
  },
  email:{
    MAIL_TO: 'Raptor_admins@nbcuni.com'
  }
};

export default configuration