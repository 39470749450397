import React from "react";
import styles from "./Loader.module.css";

export const Loader = ({ isLoading, primary = true }) => {
  return (
    <div
      className={`${styles.loader} ${!isLoading ? styles.hidden : ""} ${primary ? styles.gradient : styles.white}`}
    >
      <div className={styles.spinner}>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
      </div>
    </div>
  );
};
